import React from 'react'

const footer = () => {
    return (
        <footer>
            <p>©︎ 2023 GOTO's PORTFOLIO. All Rights Reserved.</p>
        </footer>

    )
}

export default footer