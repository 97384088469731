import React from "react";
import Side from "../components/side.js";
import Blog from "./blog.js";

const Home = () => {
  return (
    <div className="contents">
      <div className="home" >
          <Blog />
      </div>
      <Side />
    </div>
  );
};

export default Home;
